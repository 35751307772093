<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title> Переназначение задачи </template>
      <a-loader v-if="!loaded" />
      <div v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true, hideDetails: 'auto' }" @validate="validate($event)" />
      </div>
      <template v-slot:actions>
        <v-spacer />
        <a-btn-save @click="submit()" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "../../components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
  },
  data() {
    return {
      loaded: true,
      model: [
        {
          name: "user_id",
          title: "Кому назначить",
          type: "selectapi",
          api: "/manager/users/select",
          filters: { status: 1, role: { condition: ">", value: 20 } },
          validator: ["req"],
        },
        { name: "addWatcher", title: "Наблюдатель", type: "select", options: "yesNo", validator: ["req"], default: 0 },
        { name: "comment", title: "Комментарий", type: "text", validator: ["req"] },
      ],
    };
  },
  created() {
    this.resetFrom();
    this.fillForm();
  },
  watch: {
    value() {},
  },
  computed: {},
  methods: {
    async fetchData(id) {
      this.loaded = true;
    },
    afterFetchData(r) {
      this.loaded = true;
    },
    async submit() {
      if (this.saving) return;
      this.saving = true;
      if (await this.validateAll(this.data, false)) {
        this.saving = false;
        this.$emit("redirect", this.data);
        this.$emit("input");
        return true;
      }
      this.saving = false;
      return false;
    },
  },
};
</script>
